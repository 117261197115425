const html = require('choo/html');
const jwt = require('jsonwebtoken');



const url = `https://anykrypt.privaseverse.com`;
// const url = `http://localhost:8080`;





module.exports = function signin(state, emit) {



  // this will generate the jwt Token and store in state
  const authLinkGenerator = function(userEmail) {
    try {
      // verifing  the user input email correct domain or not
      const emailFilter = userEmail => {
        const pattern = /^\w+([.-]?\w+)*@gmail\.com$/;
        return pattern.test(userEmail);
      };

      if (!emailFilter(userEmail)) {
        document.getElementById('emailalertsuccess').innerHTML = '';
        document.getElementById('emailalerterr').innerHTML =
          'Only feelyprivacy.com domains are allowed.';
        console.log('Only feelyprivacy.com addresses are allowed');
      } else {
        //  creating a token for sending the email
        const token = jwt.sign({ id: userEmail }, 'secret', {
          expiresIn: '1h'
        });

        // generating email
        console.log('email is correct');
        emailSend(userEmail, token);
        document.getElementById('emailalerterr').innerHTML = '';
        document.getElementById('emailalertsuccess').innerHTML =
          'OTP will be sent to your address shortly.';
      }
    } catch (e) {
      console.log('token generator :-- ', e);
    }
  };

  // email send to the user
  const emailSend = async (to, token) => {
    try {
      fetch(`${url}/sendemail`, {
        method: 'POST',
        body: JSON.stringify({ sendTo: to, token: token}),
        headers: { 'Content-Type': 'application/json' }
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          if(data.message == "Queued!") {
            document.getElementById("OTP-input").style.display = "block"
            document.getElementById("email-input").style.display = "none"
          } else {
            console.log(data);
          }

        })
        .catch(error => console.error(error));
    } catch (e) {
      console.log('fetch req err :- ', e);
    }
  };

  const verifyOTP = async (enteredOTP) => {
    try {
      fetch(`${url}/verifyotp`, {
        method: 'POST',
        body: JSON.stringify({ enteredOTP: enteredOTP }),
        headers: { 'Content-Type': 'application/json' }
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          let otpalert = document.getElementById('otpalert');
          if(data.message == "validOTP") {
            window.location.href =  data.url;
          } else if(data.message == "OTPExpired") {
            otpalert.innerHTML = 'OTP Expired!';
          } else if(data.message == "OTPNotMatch") {
            otpalert.innerHTML = 'Invalid OTP';
          } else if(data.message == "userNotFound") {
            otpalert.innerHTML = 'Invalid OTP';
          }
          else {
            console.log(data);
          }
          setTimeout(() => {document.getElementById('otpalert').innerHTML = ''}, 5000)
        })
        .catch(error => console.log("catch:: ", error));
    } catch (e) {
      console.log('fetch req err :- ', e);
    }
  }





  return html`
    <main class="main">

    <section id="email-input"
        class=" flex flex-col items-center justify-center h-full w-full p-6 md:p-8 overflow-hidden md:rounded-xl md:shadow-big"
      >
        <h1 class="text-center text-3xl font-bold my-2">
          Enter Your Email Here
        </h1>
        <div class="mb-6">
          <input
            type="email"
            id="email"
            class="dark:bg-grey-80 border-3 border-light-blue-500 border-opacity-50 dark:text-white text-bold border border-grey-50 rounded-lg  p-3 "
            placeholder="user.name@company.com"
            required
          />
        </div>

        <div>
          <div class="h-captcha" data-sitekey="25d200cd-bcee-4eb7-af22-912ba7718ba7"></div>
        </div>
        <p
          class="max-w-md mb-4 text-center text-grey-80 leading-normal dark:text-grey-40"
        >
          An OTP / Link will be send to your email.
        </p>

        <button
          class="btn rounded-lg flex items-center"
          role="button"
          onclick="${() => {
            // console.log(document.getElementById('email').value)
            authLinkGenerator(document.getElementById('email').value);
          }}"
        >
          Generate OTP / Link
        </button>
        <p class="text-rose-60 my-2" id="emailalerterr"></p>
        <p class="text-emerald-50 my-2" id="emailalertsuccess"></p>
      </section>



      <section id="OTP-input"
        class="mx-auto flex bg-red-400 items-center justify-center h-full w-full p-6 md:p-8 overflow-hidden md:rounded-xl md:shadow-big"
      >
        <div>
          <h1 class="text-center text-3xl font-bold my-2">
            Enter OTP Here
          </h1>
          <div class="mb-6 mx-auto flex flex-col items-center">
            <input
              type="text"
              id="enteredOTP"
              class="dark:bg-grey-80 mx-auto border-3 border-light-blue-500 border-opacity-50 dark:text-white text-bold border border-grey-50 rounded-lg  p-3 my-3"
              placeholder="Enter OTP Here"
              pattern="\d{4}"
              maxlength="4"
              required
            />
          </div>
          <button
            class="btn mx-auto rounded-lg flex flex-col items-center"
            role="button"
            onclick="${() => {
              // console.log(document.getElementById('email').value)
              verifyOTP(document.getElementById('enteredOTP').value);
            }}"
          >
            Verify OTP
          </button>
          <div class="flex flex-col justify-center">
            <p class=" mx-auto text-rose-60 my-2" id="otpalert"></p>
          </div>
        </div>
      </section>
    </main>
  `;
};
