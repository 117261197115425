'use strict';
/* global WorkerGlobalScope self */
var _require = require('./log'), log = _require.log;
function reloadApp(_ref, _ref2) {
    var hotReload = _ref.hotReload, hot = _ref.hot, liveReload = _ref.liveReload;
    var isUnloading = _ref2.isUnloading, currentHash = _ref2.currentHash;
    if (isUnloading || !hotReload) {
        return;
    }
    if (hot) {
        log.info('[WDS] App hot update...');
        var hotEmitter = require('webpack/hot/emitter');
        hotEmitter.emit('webpackHotUpdate', currentHash);
        if (typeof self !== 'undefined' && self.window) {
            // broadcast update to window
            self.postMessage('webpackHotUpdate'.concat(currentHash), '*');
        }
    }    // allow refreshing the page only if liveReload isn't disabled
    else if (liveReload) {
        var rootWindow = self;
        // use parent window for reload (in case we're in an iframe with no valid src)
        var intervalId = self.setInterval(function () {
            if (rootWindow.location.protocol !== 'about:') {
                // reload immediately if protocol is valid
                applyReload(rootWindow, intervalId);
            } else {
                rootWindow = rootWindow.parent;
                if (rootWindow.parent === rootWindow) {
                    // if parent equals current window we've reached the root which would continue forever, so trigger a reload anyways
                    applyReload(rootWindow, intervalId);
                }
            }
        });
    }
    function applyReload(rootWindow, intervalId) {
        clearInterval(intervalId);
        log.info('[WDS] App updated. Reloading...');
        rootWindow.location.reload();
    }
}
module.exports = reloadApp;