'use strict';
function getCurrentScriptSource() {
    // `document.currentScript` is the most accurate way to find the current script,
    // but is not supported in all browsers.
    if (document.currentScript) {
        return document.currentScript.getAttribute('src');
    }
    // Fall back to getting all scripts in the document.
    var scriptElements = document.scripts || [];
    var currentScript = scriptElements[scriptElements.length - 1];
    if (currentScript) {
        return currentScript.getAttribute('src');
    }
    // Fail as there was no script to use.
    throw new Error('[WDS] Failed to get current script source.');
}
module.exports = getCurrentScriptSource;