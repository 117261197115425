'use strict';
var log = require('loglevel').getLogger('webpack-dev-server');
var INFO = 'info';
var WARN = 'warn';
var ERROR = 'error';
var DEBUG = 'debug';
var TRACE = 'trace';
var SILENT = 'silent';
// deprecated
// TODO: remove these at major released
// https://github.com/webpack/webpack-dev-server/pull/1825
var WARNING = 'warning';
var NONE = 'none';
// Set the default log level
log.setDefaultLevel(INFO);
function setLogLevel(level) {
    switch (level) {
    case INFO:
    case WARN:
    case ERROR:
    case DEBUG:
    case TRACE:
        log.setLevel(level);
        break;
    // deprecated
    case WARNING:
        // loglevel's warning name is different from webpack's
        log.setLevel('warn');
        break;
    // deprecated
    case NONE:
    case SILENT:
        log.disableAll();
        break;
    default:
        log.error('[WDS] Unknown clientLogLevel \''.concat(level, '\''));
    }
}
module.exports = {
    log: log,
    setLogLevel: setLogLevel
};